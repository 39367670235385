import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiService } from "../../../services/ApiService";
function AboutBlock({ data, StaticImageData }) {
    const language = useSelector(({ language }) => language.lang);
    const { title, text } = data;
    return (
        <div className={`about-block ${language === "ar" && "about-block-arabic"}`} data-aos="fade-up" data-aos-offset="200" data-aos-delay="3" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-bottom">
            <Container>
                <div className="about-block__contents">
                    <Row>
                        <Col lg={6}>
                            <h3>{title}</h3>

                            <p dangerouslySetInnerHTML={{ __html: text }} />
                        </Col>
                        <Col md={6}>
                            <img
                                // src="/images/Flower.png"
                                src={StaticImageData?.image ? apiService.imageLink + StaticImageData.image : "/images/placeholder.png"}
                                alt="k"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default AboutBlock;
