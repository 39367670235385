import React, { Component } from "react";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import { apiService } from "../../../services/ApiService";
import { authService } from "../../../services/AuthService";
import { generalServices } from "../../../services/GeneralServices";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import TextInput from "../../blocks/TextInput";
import AgentOf from "../home/AgentOf";
import { setUser } from "../../../redux/actions-reducers/user";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { ErrorMessage } from "../../blocks/ErrorMessage";
class SignUp extends Component {
    state = {
        countries: [],
        fields: {
            email: "",
            name: "",
            phone: "",
            password_confirmation: "",
            password: "",
            country: "",
            country_id: "",
        },
        errors: {
            email: "",
            name: "",
            phone: "",
            password_confirmation: "",
            password: "",
            country: "",
            country_id: "",
        },
        openEyes: true,
        marginBottomCancel: false,
    };

    async componentDidMount() {
        const { data: dataCountries } = await generalServices.getAllCountries();
        this.setState({
            countries: dataCountries,
            fields: {
                ...this.state.fields,
                country: dataCountries ? dataCountries[0]?.name : "",
            },
        });
    }

    render() {
        const onFieldChange = (name, value) =>
            this.setState({
                fields: { ...fields, [name]: value },
                errors: {
                    email: "",
                    name: "",
                    phone: "",
                    password_confirmation: "",
                    password: "",
                    country: "",
                },
            });
        const { fields, countries, errors, openEyes, marginBottomCancel } =
            this.state;
        const {
            i18n: {
                Email,
                Phone,
                logIn,
                selectedCountry,
                SignUp,
                Password,
                HaveAccount,
                ConfirmPassword,
                FullName,
            },
            language,
        } = this.props;
        return (
            <div
                className={`contact-us auth ${
                    language === "ar" && "contact-us-arabic"
                }`}
            >
                <div className="page-label" />
                <Container>
                    {/* sign up title start  */}
                    <h3 className="page-title">{SignUp}</h3>
                    {/* sign up title start  */}

                    <div className="contact-us__contents">
                        <div
                            className="form-box"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-delay="3"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="true"
                            data-aos-anchor-placement="top-bottom"
                        >
                            <Row>
                                {/* sign up form start  */}
                                <Col>
                                    <TextInput
                                        name="name"
                                        label=""
                                        placeholder={FullName}
                                        value={fields.name}
                                        onFieldChange={onFieldChange}
                                        validate={errors.name}
                                    />
                                    <TextInput
                                        name="email"
                                        label=""
                                        placeholder={Email}
                                        value={fields.email}
                                        onFieldChange={onFieldChange}
                                        validate={errors.email}
                                    />
                                    <Row>
                                        <Col lg={4} md={12}>
                                            <Form.Select
                                                defaultValue="-1"
                                                aria-label="Default select example"
                                                onChange={({ target }) => {
                                                    this.setState({
                                                        fields: {
                                                            ...fields,
                                                            country: JSON.parse(
                                                                target.value
                                                            ).name,
                                                            country_id:
                                                                JSON.parse(
                                                                    target.value
                                                                ).id,
                                                        },
                                                    });
                                                }}
                                            >
                                                <option value="-1" disabled>
                                                    {selectedCountry}
                                                </option>
                                                {countries?.map(
                                                    (
                                                        {
                                                            callingCodes,
                                                            name,
                                                            id,
                                                        },
                                                        index
                                                    ) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={JSON.stringify(
                                                                    { name, id }
                                                                )}
                                                            >
                                                                {name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Form.Select>
                                        </Col>
                                        <Col lg={8} md={12}>
                                            <TextInput
                                                name="phone"
                                                label=""
                                                placeholder={Phone}
                                                value={fields.phone}
                                                onFieldChange={onFieldChange}
                                                validate={errors.phone}
                                            />
                                        </Col>
                                    </Row>
                                    <InputGroup className="mb-3 flex input-group1">
                                        <input
                                            className="password-input"
                                            type={
                                                openEyes ? "password" : "text"
                                            }
                                            label=""
                                            name="password"
                                            placeholder={Password}
                                            value={fields.password}
                                            onChange={(e) => {
                                                this.setState({
                                                    fields: {
                                                        ...this.state.fields,
                                                        password:
                                                            e.target.value,
                                                    },
                                                });
                                            }}
                                        />
                                        <i
                                            onClick={() =>
                                                this.setState({
                                                    openEyes: !openEyes,
                                                })
                                            }
                                            className={
                                                openEyes
                                                    ? "fa fa-eye-slash fa-1x"
                                                    : "fa fa-eye fa-1x"
                                            }
                                        ></i>
                                    </InputGroup>

                                    {errors?.password && (
                                        <ErrorMessage
                                            message={errors.password}
                                        />
                                    )}
                                    <InputGroup
                                        className={`mb-3 flex input-group1`}
                                    >
                                        <input
                                            className="password-input "
                                            type={
                                                openEyes ? "password" : "text"
                                            }
                                            label=""
                                            name="password_confirmation"
                                            placeholder={ConfirmPassword}
                                            value={fields.password_confirmation}
                                            onChange={(e) => {
                                                this.setState({
                                                    fields: {
                                                        ...this.state.fields,
                                                        password_confirmation:
                                                            e.target.value,
                                                    },
                                                });
                                            }}
                                        />
                                        <i
                                            onClick={() =>
                                                this.setState({
                                                    openEyes: !openEyes,
                                                })
                                            }
                                            className={
                                                openEyes
                                                    ? "fa fa-eye-slash fa-1x"
                                                    : "fa fa-eye fa-1x"
                                            }
                                        ></i>
                                    </InputGroup>
                                    {errors?.password && (
                                        <ErrorMessage
                                            message={errors.password}
                                        />
                                    )}
                                    {/* <TextInput
                    name="password"
                    label=""
                    placeholder="Password"
                    value={fields.password}
                    onFieldChange={onFieldChange}
                    validate={errors.password}
                  />
                  
                  <TextInput
                    name="password_confirmation"
                    label=""
                    placeholder="Confirm Password"
                    value={fields.password_confirmation}
                    onFieldChange={onFieldChange}
                  /> */}

                                    <button
                                        className="submit-button"
                                        onClick={() => {
                                            this.submit();
                                            this.setState({
                                                marginBottomCancel: true,
                                            });
                                        }}
                                    >
                                        {SignUp}
                                    </button>
                                    <p>
                                        {HaveAccount}{" "}
                                        <a href="/login">{logIn}</a>
                                    </p>
                                </Col>
                                {/* sign up form end  */}

                                {/* Sign up main img start */}
                                <Col md={6}>
                                    <img
                                        src="/images/Group 50411.png"
                                        alt="l"
                                        className="main-form-img"
                                    />
                                </Col>
                                {/* Sign up main img end */}
                            </Row>
                        </div>
                    </div>
                </Container>
                <AgentOf />
            </div>
        );
    }

    submit = async () => {
        const { fields } = this.state;
        const { success, data, message, errors } = await authService.SignUp(
            fields
        );

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                console.log(handelErrors, "handelErrors");
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }

        apiService.storeToken(data.access_token);
        this.props.setUser({ ...data.user });

        window.location.href = "/";
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(SignUp);
