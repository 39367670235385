import { apiService } from "./ApiService";
import { handleResponse } from "../utils/misc";
import { apiEndPoints } from "../api";
class BookService {
    async getBookData(bookId) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getBookData}?book_id=${bookId}`)
                .then(({ data }) => data);

            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getBooksBySeries(series_id, subject_id, class_id, stages_id) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getBooksBySeries}?series_id=${series_id}&subject_id=${subject_id}&class_id=${class_id}&stages_id=${stages_id}`)
                .then(({ data }) => data);

            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSeriesByClass(class_id, subject_id, stage_id) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getSeries}?subject_id=${subject_id}&class_id=${class_id}&stages_id=${stage_id}`)
                .then(({ data }) => data);

            return handleResponse({ success: true, data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
}

export const bookService = new BookService();
