import React from "react";
import { Col, Row } from "react-bootstrap";
import { apiService } from "../../services/ApiService";

function DigitalPlatformsBlock({ item }) {
    return (
        <a
            href={item.url}
            className="platform-block"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="3"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
        >
            <Row>
                <Col sm={12} md={4}>
                    <div
                        className="platform-block__img__container"
                        style={{ backgroundColor: `${item.background_color}` }}
                    >
                        <img src={apiService.imageLink + item.image} />
                    </div>
                </Col>
                <Col sm={12} md={8}>
                    <div className="platform-block__info ps-0">
                        <p
                            className="platform-block__info__title"
                            style={{ color: `${item.background_color}` }}
                        >
                            {item.name}
                        </p>
                        <p className="platform-block__info__disc">
                            {/* {item.description} */}
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit, sed diam nonummy nibh euismod tincidunt ut
                            laoreet dolore magna aliquam erat volutpat. Ut wisi
                            enim ad minim veniam, quis nostrud exerci tation
                            ullamcorper suscipit lobortis nisl ut aliquip ex ea
                            commodo consequat. Duis autem vel eum iriure dolor
                            in hendrerit in vulputate velit esse molestie
                            consequat, vel illum dolore eu feugiat nulla
                            facilisis at vero eros et accumsan et iusto odio
                            dignissim qui blandit praesent luptatum zzril
                            delenit augue duis
                        </p>
                    </div>
                </Col>
            </Row>
        </a>
    );
}

export default DigitalPlatformsBlock;
