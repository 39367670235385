import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { profileService } from "../../../services/Profile";
import MainLoader from "../../blocks/MainLoader";
import Product from "../../blocks/Product";
import AgentOf from "../home/AgentOf";
import NewTitles from "../home/NewTitles";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { connect } from "react-redux";
import { generalServices } from "../../../services/GeneralServices";
import { apiService } from "../../../services/ApiService";
import { homeService } from "../../../services/HomeService";
import DigitalPlatformsBlock from "../../blocks/DigitalPlatformsBlock";

class DigitalPlatforms extends Component {
    state = {
        books: [],
        relatedBooks: [],
        pageLoader: true,
        teamData: [],
        digitalPlatforms: [],
        saleBooks: [],
    };
    getDigitalSolutions = async () => {
        const { data } = await generalServices.getDigitalSolutions();
        this.setState({
            digitalPlatforms: data,
        });
    };
    componentDidMount() {
        Promise.all([this.getOurTeam(), this.getDigitalSolutions(), this.getHomeData()]).then(() => {
            this.setState({
                pageLoader: false,
            });
        });
    }
    // getUserFavorite = async () => {
    //     const { success, data } = await profileService.getUserFavorite();

    //     if (!success) return;

    //     this.setState({
    //         books: data.books,
    //         relatedBooks: data.relatedBooks,
    //         pageLoader: false,
    //     });
    // };
    getOurTeam = async () => {
        const { success, data } = await generalServices.getOurTeam();
        if (!success) return;
        this.setState({ teamData: data });
    };
    pageLoader = () => {
        this.setState({
            pageLoader: false,
        });
    };

    render() {
        const {
            i18n: { OurTeam, FavoritesEmpty, digitalPlatformsName, BigSale },
            language,
        } = this.props;
        const { pageLoader, books, relatedBooks, teamData, digitalPlatforms } = this.state;
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div className={`contact-us ${language === "ar" && "contact-us-arabic"}`}>
                <div className="page-label" />
                <div className="favorites-products team-products">
                    <Container>
                        <h3 className="page-title">{digitalPlatformsName}</h3>
                        {books?.length > 0 ? (
                            <Row className="team-products__row" style={{ marginTop: "70px" }}>
                                {digitalPlatforms?.map((item) => {
                                    // console.log(item);
                                    return (
                                        <Col md={12}>
                                            <DigitalPlatformsBlock item={item} />
                                            {/* <div className="team-card">
                                                <a href={item.url}>
                                                    <div className="team-card__img-container">
                                                        {" "}
                                                        <img
                                                            className="team-card__img"
                                                            src={
                                                                item.image
                                                                    ? apiService.imageLink +
                                                                      item.image
                                                                    : "/images/zoro.jpg"
                                                            }
                                                            alt="person"
                                                        />
                                                    </div>{" "}
                                                    <h3 className="team-card__name">
                                                        {item.name}
                                                    </h3>
                                                    <h3 className="team-card__position">
                                                        {item.position}
                                                    </h3>
                                                    <p className="team-card__text">
                                                        {item.brief}
                                                    </p>
                                                    <div class="overlay">
                                                    </div>
                                                </a>
                                            </div> */}
                                        </Col>
                                    );
                                })}
                            </Row>
                        ) : (
                            <div className="form-box">
                                <div className="empty-img">
                                    <img src="/images/empty.png" alt="l" />
                                    <h6>{FavoritesEmpty}</h6>
                                </div>
                            </div>
                        )}
                    </Container>
                    <AgentOf />

                    <NewTitles title={BigSale} data={this.state.books} reFetchData={() => this.getHomeData()} type="bigsale" />
                    {console.log(books, "books")}
                </div>
            </div>
        );
    }

    getHomeData = async () => {
        const { success, data } = await homeService.getHomeData();

        console.log(data, "fata0");
        if (!success) return;
        this.setState({
            books: data.books,
            about: data.about,
            featured: data.featured,
            partners: data.partners,
            saleBooks: data.saleBooks,
            blogs: data.blogs,
            pageLoader: false,
        });
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setLanguage })(DigitalPlatforms);
