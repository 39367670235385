import { createSlice } from "@reduxjs/toolkit";

const stagesSlice = createSlice({
    name: "stages",
    initialState: [],

    reducers: {
        setStages: (stages, { payload }) => {
            return [...payload];
        },
    },
});

export const { setStages } = stagesSlice.actions;

export default stagesSlice.reducer;
