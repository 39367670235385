export const apiEndPoints = {
    login: "auth/sign_in",
    SignUp: "auth/sign_up",
    getHomeData: "getHomePageData",
    sendMessage: "sendMessage",
    getBooksBySeries: "getBooksBySeries",
    getSeries: "getSeriesByClass",
    getSubjectByStage: "getSubjectByStage",
    getClassBySubject: "getClassBySubject",
    profile: {
        ChangeProfile: "user/updateProfile",
        resetPassword: "user/updatePassword",
        getUserData: "user/getUserData",
        checkEmail: "forgot-password",
        getUserFavourite: "user/getUserFavourite",
        getOrders: "user/getOrders",
    },
    location: {
        getUserAddresses: "user/getUserAddresses",
        addLocation: "user/addLocation",
        updateLocation: "user/updateLocation/",
        deleteLocation: "user/deleteLocation/",
    },
    cart: {
        getCartData: "user/getCartData",
    },
    addOrRemoveFavourite: "user/addOrRemoveFavourite",
    addToCart: "user/addBooksToCart",
    deleteBooksFromCart: "user/deleteBooksFromCart",
    getBookData: "getBookData",
    checkout: "user/checkout",
    getPartners: "getPartners",
    getClasses: "getClasses",
    getClassesNew: "getClassesNew",
    // getClassesNew: "getStages",
    getStages: "getStages",
    getSubjects: "getSubjects",
    searchBook: "searchBook",
    getFooterData: "getFooterData",
    getPublicPageData: "getPublicPageData",
    getBooksData: "getBooksData",
    getSubject: "getSubject",
    getSocialLinks: "getSocialLinks",
    getDigitalSolutions: "getDigitalSolutions",
    getStaticImage: "getStaticImage",
    getOurTeam: "getOurTeam",
    getAllCountries: "getAllCountries",
    getSliders: "getSliders",
    getSetting: "getSetting",
};
