import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { profileService } from "../../../services/Profile";
import MainLoader from "../../blocks/MainLoader";
import Product from "../../blocks/Product";
import AgentOf from "../home/AgentOf";
import NewTitles from "../home/NewTitles";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { connect } from "react-redux";
import { generalServices } from "../../../services/GeneralServices";
import { apiService } from "../../../services/ApiService";

class Team extends Component {
    state = {
        books: [1, 2, 3, 4],
        relatedBooks: [],
        pageLoader: true,
        teamData: [],
    };

    componentDidMount() {
        this.pageLoader();
        this.getOurTeam();
    }
    // getUserFavorite = async () => {
    //     const { success, data } = await profileService.getUserFavorite();

    //     if (!success) return;

    //     this.setState({
    //         books: data.books,
    //         relatedBooks: data.relatedBooks,
    //         pageLoader: false,
    //     });
    // };
    getOurTeam = async () => {
        const { success, data } = await generalServices.getOurTeam();
        if (!success) return;
        this.setState({ teamData: data });
    };
    pageLoader = () => {
        this.setState({
            pageLoader: false,
        });
    };

    render() {
        const {
            i18n: { OurTeam, FavoritesEmpty },
            language,
        } = this.props;
        const { pageLoader, books, relatedBooks, teamData } = this.state;
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div
                className={`contact-us ${
                    language === "ar" && "contact-us-arabic"
                }`}
            >
                <div className="page-label" />
                <div className="favorites-products team-products">
                    <Container>
                        <h3 className="page-title">{OurTeam}</h3>
                        {books?.length > 0 ? (
                            <Row className="team-products__row">
                                {teamData?.map((item) => {
                                    return (
                                        <Col sm={6} md={6} lg={4} xl={3}>
                                            <div className="team-card">
                                                <div className="team-card__img-container">
                                                    {" "}
                                                    <img
                                                        className="team-card__img"
                                                        src={
                                                            item.image
                                                                ? apiService.imageLink +
                                                                  item.image
                                                                : "/images/zoro.jpg"
                                                        }
                                                        // src="/images/zoro.jpg"
                                                        alt="person"
                                                    />
                                                </div>
                                                <h3 className="team-card__name">
                                                    {item.name}
                                                </h3>
                                                <h3 className="team-card__position">
                                                    {item.position}
                                                </h3>
                                                <p className="team-card__text">
                                                    {item.brief}
                                                </p>

                                                <div class="overlay">
                                                    {/* <div class="text">
                                                        Hello World
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        ) : (
                            <div className="form-box">
                                <div className="empty-img">
                                    <img src="/images/empty.png" alt="l" />
                                    <h6>{FavoritesEmpty}</h6>
                                </div>
                            </div>
                        )}
                    </Container>
                </div>

                {/* <NewTitles
                    title="Books you may like"
                    data={relatedBooks}
                    reFetchData={() => this.getUserFavorite()}
                />

                <AgentOf /> */}
            </div>
        );
    }
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setLanguage })(Team);
