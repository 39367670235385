import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { profileService } from "../../../services/Profile";
import MainLoader from "../../blocks/MainLoader";
import Product from "../../blocks/Product";
import AgentOf from "../home/AgentOf";
import NewTitles from "../home/NewTitles";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { connect } from "react-redux";

class Favorites extends Component {
    state = {
        books: [],
        relatedBooks: [],
        pageLoader: true,
    };

    componentDidMount() {
        this.getUserFavorite();
    }
    getUserFavorite = async () => {
        const { success, data } = await profileService.getUserFavorite();

        if (!success) return;

        this.setState({
            books: data.books,
            relatedBooks: data.relatedBooks,
            pageLoader: false,
        });
    };

    render() {
        const {
            i18n: { MyFavorites, FavoritesEmpty, BooksYouMayLike },
            language,
        } = this.props;
        const { pageLoader, books, relatedBooks } = this.state;
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div
                className={`contact-us ${
                    language === "ar" && "contact-us-arabic"
                }`}
            >
                <div className="page-label" />
                <div className="favorites-products">
                    <Container>
                        <h3 className="page-title">{MyFavorites}</h3>
                        {books?.length > 0 ? (
                            <Row>
                                {books?.map((item) => {
                                    return (
                                        <Col sm={6} md={4} xl={3}>
                                            <Product
                                                item={item}
                                                reFetchData={() =>
                                                    this.getUserFavorite()
                                                }
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        ) : (
                            <div className="form-box">
                                <div className="empty-img">
                                    <img src="/images/empty.png" alt="l" />
                                    <h6>{FavoritesEmpty}</h6>
                                </div>
                            </div>
                        )}
                    </Container>
                </div>

                <NewTitles
                    title={BooksYouMayLike}
                    data={relatedBooks}
                    reFetchData={() => this.getUserFavorite()}
                />

                <AgentOf />
            </div>
        );
    }
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(Favorites);
