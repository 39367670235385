import React, { Component } from "react";
import { Col, Container, Row, InputGroup, FormControl } from "react-bootstrap";
import AgentOf from "../../pages/home/AgentOf";
import NewTitles from "../home/NewTitles";
import "../../../assets/style/components/pages/productDetails/_productdetails.scss";
import { slide as Menu } from "react-burger-menu";
import { setUser } from "../../../redux/actions-reducers/user";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { bookService } from "../../../services/bookService";
import MainLoader from "../../blocks/MainLoader";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralServices";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import MultiSideMenu from "../../blocks/MultiLevelSlider";
import { displayAlert } from "../../../utils/misc";
//

class ProductDetails extends Component {
    state = {
        menuOpen: false,
        qty: 1,
        key: "",
        relatedBooks: [],
        book: {},
        images: [],
        subjects: [],
        classes: [],
        ClassesNew: [],
        options: [],
        pageLoader: true,
        userLoggedIn: localStorage.getItem("accessToken"),
        IsArabic: false,
        quantity: "",
        mainImage: {},
    };

    componentDidMount() {
        Promise.all([this.getBookData(), this.getClasses(), this.getSubjects()]);
        this.getBookData();

        this.props.language === "ar" ? this.setState({ IsArabic: true }) : this.setState({ IsArabic: false });
    }

    getBookData = async () => {
        const { success, data } = await bookService.getBookData(this.props.match.params.id);

        if (!success) return;

        // let images = data?.book?.book_images.map(({ image_full, image_thumb }) => ({
        //     original: apiService.imageLink + image_full,
        //     thumbnail: apiService.imageLink + image_thumb,
        // }));
        let images = data?.book?.book_menu.map(({ link_icon, link }) => ({
            original: apiService.imageLink + data?.book?.book_images[0]?.image_full,
            thumbnail: apiService.imageLink + link_icon,
            link,
        }));

        // images.push({
        //     original: apiService.imageLink + data?.book?.book_images[0]?.image_full,
        //     thumbnail: apiService.imageLink + data?.book?.book_images[0]?.image_thumb,
        // });
        this.setState({
            book: data?.book,
            relatedBooks: data?.relatedBooks,
            pageLoader: false,
            images: images,
            quantity: data?.book.quantity,
            min_order: data?.book.min_order,
            qty: data?.book.min_order,
            mainImage: {
                original: apiService.imageLink + data?.book?.book_images[0]?.image_full,
                thumbnail: apiService.imageLink + data?.book?.book_images[0]?.image_thumb,
            },
        });
    };

    getClasses = async () => {
        const { success, data } = await generalServices.getClasses();

        if (!success) return;
        this.setState({
            classes: data,
        });
    };

    getSubjects = async () => {
        const { success, data } = await generalServices.getSubjects();

        if (!success) return;

        this.setState({
            subjects: data,
            pageLoader: false,
        });
    };

    //

    // render main img gallery carousel
    _renderCustomControls(item) {
        return <img src={this.state.mainImage.original} alt="l" />;
    }
    // end render main img gallery carousel

    // render Thumb img gallery carousel

    _renderCustomThumbs = (item) => {
        return <img src={item.thumbnail} onClick={() => window.open(item?.link, "_blank")} alt="l" style={{ width: "100%" }} />;
    };
    // end render Thumb img gallery carousel
    //
    handleSidebarToggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    };
    handleChange = ({ target: { value } }) => {
        let qty;
        const { min_order, quantity } = this.state;
        if (value < min_order) {
            // qty = min_order;
            qty = +value;
        } else {
            if (value > quantity) {
                qty = quantity;
            } else {
                qty = +value;
            }
        }

        this.setState({
            qty,
        });

        // this.setState({
        //     qty:
        //         event.target.value < this.state.min_order
        //             ? this.state.min_order
        //             : event.target.value > this.state.quantity
        //             ? this.state.quantity
        //             : event.target.value,
        // });
    };
    render() {
        const closeMenu = () => {
            this.setState({
                menuOpen: false,
                descriptionText: false,
            });
        };

        const { menuOpen, images, qty, menu, descriptionText, book, relatedBooks, pageLoader, key, classes, subjects, min_order, IsArabic, options, ClassesNew } = this.state;
        const {
            i18n: { Categories, Classes, Subjects, Search1, price, WishList, AddToCart, Showless, readmore, AuthorDetails, RelatedBooks, OutOfStock, DeletedFromCart, Success, AddedToCart, Done },
            language,
        } = this.props;

        // const readMoreText =
        //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book?. It has survived not only five centuries, but also the leap into electronic typesetting,";
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div className={`product-details-main ${language === "ar" && "product-details-main-arabic"}`}>
                {/* <div className="search-block-container">
                    <Container>
                        <div className="search-block flex">
                            <div className="category-push-menu">
                                <MultiSideMenu
                                    menuOpen={menuOpen}
                                    options={options}
                                    handleSidebarToggle={
                                        this.handleSidebarToggle
                                    }
                                />
                                <button
                                    onClick={() => this.handleSidebarToggle()}
                                >
                                    <i className="fa fa-bars" />
                                </button>
                                
                            </div>
                            <span>{Categories}</span>
                            <InputGroup size="sm" className="">
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                    <img
                                        src="/images/fi-rr-search.svg"
                                        alt="l"
                                    />
                                </InputGroup.Text>
                                <FormControl
                                    onChange={(e) => {
                                        this.setState({
                                            key: e.target.value,
                                        });
                                    }}
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                />
                                <InputGroup.Text
                                    id="inputGroup-sizing-sm"
                                    onClick={() => {
                                        if (key === "") {
                                        } else {
                                            this.Search(key);
                                        }
                                    }}
                                >
                                    {Search1}
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Container>
                </div> */}

                <Container>
                    {/* product-browser-detail Start */}
                    <div className="product-browser-detail">
                        <Row>
                            <Col sm={12} lg={3}>
                                {" "}
                                <ImageGallery items={images.length < 1 ? [1] : images} thumbnailPosition="bottom" showNav={false} showFullscreenButton={false} showPlayButton={false} showThumbnails={true} renderItem={(images) => this._renderCustomControls(images)} renderThumbInner={(images) => this._renderCustomThumbs(images)} />
                            </Col>
                            <Col sm={12} lg={9}>
                                <div className="product-browser-detail__info">
                                    <h3>{book?.title}</h3>
                                    <h5>ISBN : {book?.book_code}</h5>
                                    <p>
                                        {book?.description?.substr(0, descriptionText ? book?.description.length : 300)}

                                        {book?.description?.length > 300 &&
                                            (descriptionText ? (
                                                <span
                                                    onClick={() =>
                                                        this.setState({
                                                            descriptionText: false,
                                                        })
                                                    }
                                                    className="read-more"
                                                >
                                                    {Showless}
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() =>
                                                        this.setState({
                                                            descriptionText: true,
                                                        })
                                                    }
                                                    className="read-more"
                                                >
                                                    {readmore}
                                                </span>
                                            ))}
                                    </p>

                                    <div className="product-browser-detail__info__buttons">
                                        <div className="price-quantity big-sale__card--buttons flex">
                                            <div>
                                                <span className="price-quantity__price-span">{price}</span>
                                                <span className="new-price">{book?.campaign ? this.newPrice(book?.campaign, book?.price) : book?.price}$</span>
                                                {book?.campaign && <span className="old-price">{book?.price}$</span>}
                                            </div>

                                            {/* qty of product start   */}
                                            <div className="qty flex">
                                                {/* mins  button start  */}
                                                <span
                                                    onClick={() => {
                                                        if (qty > 1) {
                                                            if (qty > min_order) {
                                                                this.setState({
                                                                    qty: qty - 1,
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    qty: min_order,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-minus"></i>
                                                </span>
                                                {/* mins  button end  */}

                                                {/* input (value) qty start  */}
                                                <input
                                                    type="tel"
                                                    value={qty}
                                                    onBlur={() =>
                                                        qty < min_order
                                                            ? this.setState({
                                                                  qty: min_order,
                                                              })
                                                            : this.setState({
                                                                  qty: qty,
                                                              })
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                {/* input (value) qty end  */}

                                                {/* plus button start  */}
                                                <span
                                                    onClick={() => {
                                                        if (qty < book.quantity) {
                                                            this.setState({
                                                                qty: qty + 1,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </span>
                                                {/* plus button end  */}
                                            </div>
                                            {/* qty of product end  */}
                                        </div>
                                        <div className="add-to-cart-wish-list big-sale__card--buttons flex">
                                            <button className={book?.is_fav ? "add-to-fav--active add-to-fav flex" : "add-to-fav flex"} onClick={() => (this.state.userLoggedIn ? this.addRemoveFavorites(book?.id, book?.is_fav) : (window.location.href = "/login"))}>
                                                <img src="/images/fi-rr-heart2.svg" alt="/" />
                                                <span>{WishList}</span>
                                            </button>

                                            {this.state.quantity >= 1 ? (
                                                <button
                                                    className={book?.in_cart ? "add-to-cart added-to-cart flex" : "add-to-cart flex"}
                                                    onClick={() => {
                                                        if (!this.state.userLoggedIn) {
                                                            window.location.href = "/login";
                                                        } else {
                                                            if (book?.in_cart) {
                                                                this.deleteBooksFromCart(book?.id).then(() => {
                                                                    displayAlert(Success, DeletedFromCart, "success", Done, 3000);
                                                                });
                                                            } else {
                                                                this.addBooksToCart(book?.id, qty).then(() => {
                                                                    displayAlert(Success, AddedToCart, "success", Done, 3000);
                                                                });
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <img src="/images/Group 511.svg" alt="/" />
                                                    <span>{AddToCart}</span>
                                                </button>
                                            ) : (
                                                <button
                                                    className={"add-to-cart added-to-cart flex out_of_stock"}
                                                    onClick={() => {
                                                        if (!this.state.userLoggedIn) {
                                                            window.location.href = "/login";
                                                        } else {
                                                            if (book?.in_cart) {
                                                                this.deleteBooksFromCart(book?.id);
                                                            } else {
                                                                this.addBooksToCart(book?.id, qty);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {OutOfStock}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col sm={5} lg={2}>
                                <div className="product-browser-detail__categories">
                                    {book?.book_menu?.length > 0 && (
                                        <div className="product-browser-detail__categories__titles">
                                            {book?.book_menu.map(({ menu_name, link, link_icon }) => {
                                                return (
                                                    <a target="_blank" rel="noreferrer" href={link}>
                                                        <img src={apiService.imageLink + link_icon} alt="" width="40px" title={menu_name} />
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    )}
                                  
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                    {/* product-browser-detail End */}
                </Container>
                <br />
                <br />
                {book?.authorDetails !== null && (
                    <Container fluid style={{ backgroundColor: "#F0F4F7", padding: "20px" }}>
                        <Row>
                            <Col className="section-title">
                                <h3 className="p-0 m-0">{AuthorDetails}</h3>
                                <br />
                                {book?.authorDetails}
                            </Col>
                        </Row>
                    </Container>
                )}

                <div className="product-details">
                    <NewTitles title={RelatedBooks} data={relatedBooks} reFetchData={() => this.getBookData()} />

                    <AgentOf />
                </div>
            </div>
        );
    }

    // calc new price
    newPrice = (campaign, price) => {
        let discountAmount = (price * campaign.percentage) / 100;

        let priceAfterDiscount = 0;
        if (discountAmount > campaign.fixed_amount) {
            priceAfterDiscount = price - campaign.fixed_amount;
            return priceAfterDiscount;
        } else {
            priceAfterDiscount = price - discountAmount;
            return priceAfterDiscount;
        }
    };
    // calc new price end

    /// add remove favorites
    addRemoveFavorites = async (id, is_fav) => {
        const { success } = await generalServices.addOrRemoveFavourite(id);
        if (!success) return;
        this.props.setUser({
            fav_count: is_fav ? this.props.currentUser.fav_count - 1 : this.props.currentUser.fav_count + 1,
        });

        this.getBookData();
    };

    /// add remove favorites end

    // add Books To Cart
    addBooksToCart = async (id, qty) => {
        const { success } = await generalServices.addToCart(id, qty);
        if (!success) return;
        this.props.setUser({
            cart_count: this.props.currentUser.cart_count + 1,
        });

        this.getBookData();
    };
    // add Books To Cart end

    //  delete Books From Cart
    deleteBooksFromCart = async (id) => {
        const { success } = await generalServices.deleteBooksFromCart(id);
        if (!success) return;
        this.props.setUser({
            cart_count: this.props.currentUser.cart_count - 1,
        });

        this.getBookData();
    };
    //  delete Books From Cart end

    //  Search end

    Search = (key) => {
        if (key.includes("%")) {
            window.location.href = "/Search/" + key.replace("%", "%25");
        } else {
            window.location.href = "/Search/" + key;
        }
    };

    //  Search end
}

const mapStateToProps = ({ currentUser, language }) => ({
    currentUser,
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
});

export default connect(mapStateToProps, { setUser })(ProductDetails);
