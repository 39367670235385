import { apiService } from "./ApiService";
import { handleResponse } from "../utils/misc";
import countriesData from "../countries.json";
import { apiEndPoints } from "../api";
class GeneralServices {
    async getAllCountries() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getAllCountries}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
        // try {
        //     const { countries } = countriesData;

        //     return handleResponse({ success: true, countries });
        // } catch ({ response }) {
        //     // return handleResponse({ success: false, message: response.data.message, data: [] });
        // }
    }
    async getSliders() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getSliders}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async sendContactMessage(messageData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.sendMessage, messageData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async addOrRemoveFavourite(book_id) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.addOrRemoveFavourite, { book_id })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async addToCart(book_id, quantity) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.addToCart, { book_id, quantity })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async deleteBooksFromCart(book_id) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.deleteBooksFromCart, { book_id })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async checkout(orderData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.checkout, orderData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getPartners() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getPartners)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getClasses() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getClasses)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getClassesNew() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getClassesNew)
                .then((data) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getStages() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getStages)
                .then((data) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSubjectByStage(stages_id) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getSubjectByStage + "?stages_id=" + stages_id)
                .then(({ data }) => data);
            return handleResponse({ success: true, data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getClassBySubject(subject_id, stages_id) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getClassBySubject + "?subject_id=" + subject_id + "&stages_id=" + stages_id)
                .then(({ data }) => data);
            return handleResponse({ success: true, data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSubjects() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getSubjects)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getFooterData() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getFooterData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSettings() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getSetting)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSocialLinks() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.getSocialLinks)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async searchBook(key) {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.searchBook}?key=${key}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getOurTeam() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getOurTeam}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getPublicPageData(machine_name) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getPublicPageData}?machine_name=${machine_name}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getBooksData(classId, subjectID, seriesId) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getBooksData}?class_id=${classId}&subject_id=${subjectID}&series_id=${seriesId}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSubject(id) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getSubject}?id=${id}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    async getDigitalSolutions() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getDigitalSolutions}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    async getStaticImage(section) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.getStaticImage}?section=${section}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
}

export const generalServices = new GeneralServices();
