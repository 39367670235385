import React, { Component } from "react";
import { Col, Container, Row, Modal, Form } from "react-bootstrap";
import { cartService } from "../../../services/CartService";
import { generalServices } from "../../../services/GeneralServices";
import { locationService } from "../../../services/LocationService";
import { displayAlert } from "../../../utils/misc";
import MainLoader from "../../blocks/MainLoader";
import TextInput from "../../blocks/TextInput";
import AgentOf from "../home/AgentOf";
import NewTitles from "../home/NewTitles";
import Location from "../Location/Location";
import HorizontalProduct from "./HorizontalProduct";
import { setUser } from "../../../redux/actions-reducers/user";
import { connect } from "react-redux";
// import language, {
//     setLanguage,
// } from "../../../redux/actions-reducers/language";
import Global from "../../../language/Global.json";
import { GoogleApiWrapper } from "google-maps-react";
import LocationPicker from "react-location-picker";
/* Default position */
const defaultPosition = {
    lat: 31.963158,
    lng: 35.930359,
};

class Cart extends Component {
    state = {
        step: 1,
        qty: 1,
        selectedLocation: {},
        selectedPayment: -1,
        books: [],
        locations: [],
        AddAddress: false,
        pageLoader: true,
        countries: [],
        position: {
            lat: 31.963158,
            lng: 35.930359,
        },
        fields: {
            address: "",
            name: "",
            phone: "",
            country: "",
            delivery_cost: "",
            longitude: defaultPosition.lng,
            latitude: defaultPosition.lat,
            country_id: "",
        },
        deliveryCost: false,
        CashOnDeliveryCost: 0,
    };

    closeModal = () => {
        this.setState({
            AddAddress: false,
        });
    };

    componentDidMount() {
        Promise.all([
            this.getCartData(),
            this.getAllCountries(),
            this.getUserAddresses(),
        ]);
    }

    getCartData = async () => {
        const { data } = await cartService.getCartData();
        this.setState({
            books: data,
        });
    };

    getAllCountries = async () => {
        const { data, success } = await generalServices.getAllCountries();
        if (!success) return;

        this.setState({
            countries: data,
            fields: {
                ...this.state.fields,
                country: data[0].name,
                delivery_cost: data[0].delivery_cost,
            },
        });
    };

    getUserAddresses = async () => {
        const { success, data } = await locationService.getUserAddresses();

        if (!success) return;
        this.setState({
            locations: data,
            selectedLocation: {},
            pageLoader: false,
        });
    };

    handleLocationChange = ({ position, address, places }) => {
        // Set new location
        this.setState({
            position,
            address,
            fields: {
                ...this.state.fields,
                longitude: position.lng,
                latitude: position.lat,
            },
        });
    };

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            windowProps: props,
        });
    };
    onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    render() {
        const {
            step,
            selectedLocation,
            selectedPayment,
            fields,
            AddAddress,
            books,
            pageLoader,
            locations,
            countries,
            CashOnDeliveryCost,
        } = this.state;
        const {
            i18n: {
                YourCart,
                CheckYourBooks,
                YourAddress,
                PaymentMethod,
                Location: locationText,
                Payment,
                Invoice,
                Price,
                DELEVERYFEE,
                TAX1,
                AddLocation,
                Books,
                Total,
                AddYourLocation,
                Name,
                Phone,
                Address,
                Continue,
                EmptyCart,
                Warning,
                PleaseSelectLocation,
                PleaseSelectPaymentMethod,
                CheckOut,
                CashDoor,
                CreditCard,
                BooksYouMayLike,
                selectedCountry,
            },
            language,
        } = this.props;
        const onFieldChange = (name, value) =>
            this.setState({ fields: { ...fields, [name]: value } });
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div
                className={`contact-us cart  ${
                    language === "ar" && "contact-us-arabic "
                }`}
            >
                <div className="page-label" />
                <Container>
                    <div className=" cart-header">
                        <Row>
                            <Col md={6}>
                                <h3 className="page-title">{YourCart}</h3>
                            </Col>
                            <Col md={6}>
                                <div className="steps">
                                    <ul>
                                        {/* step 1 label  start  */}
                                        <li>
                                            <div
                                                className={
                                                    step >= 1
                                                        ? "step stepActive"
                                                        : "step"
                                                }
                                            >
                                                <span
                                                    className={
                                                        step >= 1
                                                            ? " bg-stepActive"
                                                            : ""
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            step: 1,
                                                        })
                                                    }
                                                >
                                                    {step > 1 ? (
                                                        <i className="fa fa-check"></i>
                                                    ) : (
                                                        1
                                                    )}
                                                </span>
                                                <p> {CheckYourBooks}</p>
                                            </div>
                                        </li>
                                        {/* step 1 label  end  */}
                                        <li
                                            className={
                                                step >= 2
                                                    ? "step-hr bg-stepActive"
                                                    : "step-hr"
                                            }
                                        ></li>
                                        {/* step 2 label  start  */}

                                        <li>
                                            <div
                                                className={
                                                    step >= 2
                                                        ? "step stepActive"
                                                        : "step"
                                                }
                                            >
                                                <span
                                                    className={
                                                        step >= 2
                                                            ? " bg-stepActive"
                                                            : ""
                                                    }
                                                    onClick={() => {
                                                        if (books?.length > 0) {
                                                            this.setState({
                                                                step: 2,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {step > 2 ? (
                                                        <i className="fa fa-check"></i>
                                                    ) : (
                                                        2
                                                    )}
                                                </span>
                                                <p>{YourAddress}</p>
                                            </div>
                                        </li>
                                        <li
                                            className={
                                                step >= 3
                                                    ? "step-hr bg-stepActive"
                                                    : "step-hr"
                                            }
                                        ></li>
                                        {/* step 2 label  end  */}
                                        {/* step 3 label  start  */}

                                        <li>
                                            <div
                                                className={
                                                    step >= 3
                                                        ? "step stepActive"
                                                        : "step"
                                                }
                                            >
                                                <span
                                                    className={
                                                        step >= 3
                                                            ? " bg-stepActive"
                                                            : ""
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            selectedLocation.id
                                                        ) {
                                                            this.setState({
                                                                step: 3,
                                                            });
                                                        } else {
                                                            displayAlert(
                                                                Warning,
                                                                PleaseSelectLocation,
                                                                "warning"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {step > 3 ? (
                                                        <i className="fa fa-check"></i>
                                                    ) : (
                                                        3
                                                    )}
                                                </span>
                                                <p> {PaymentMethod}</p>
                                            </div>
                                        </li>
                                        {/* step 3 label  end  */}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form-box">
                        {books?.length > 0 ? (
                            <Row>
                                <Col md={6}>
                                    {step === 1 ? (
                                        <>
                                            <h4>{CheckYourBooks}</h4>
                                            {books.map((item, id) => {
                                                return (
                                                    <HorizontalProduct
                                                        key={id}
                                                        withDelete
                                                        data={item}
                                                        reFetchData={() =>
                                                            this.getCartData()
                                                        }
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : step === 2 ? (
                                        <>
                                            <h4>{locationText}</h4>
                                            {locations.map((item, id) => {
                                                return (
                                                    <Location
                                                        key={id}
                                                        widthCheck
                                                        locationData={item}
                                                        deliveryCost={
                                                            this.state
                                                                .deliveryCost
                                                        }
                                                        getUserAddresses={() =>
                                                            this.getUserAddresses()
                                                        }
                                                        selectedLocation={
                                                            selectedLocation
                                                        }
                                                        changeLocation={(
                                                            locationData
                                                        ) =>
                                                            this.selectedLocation(
                                                                locationData
                                                            )
                                                        }
                                                    />
                                                );
                                            })}

                                            <button
                                                className="submit-button"
                                                onClick={() =>
                                                    this.setState({
                                                        AddAddress: true,
                                                    })
                                                }
                                            >
                                                {AddLocation}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <h4>{Payment}</h4>

                                            {this.payment(
                                                CashDoor,
                                                "+YOU CAN PAY BY CASH + 3$",
                                                1
                                            )}
                                            {this.payment(
                                                CreditCard,
                                                'YOU CAN PAY BY CARD " VISA OR MASTER CARD "',
                                                2
                                            )}
                                        </>
                                    )}
                                </Col>
                                <Col md={6}>
                                    <div className="invoice">
                                        <h4>{Invoice}</h4>
                                        {/* invoice list start  */}
                                        <ul>
                                            <li className="title-list">
                                                <span className="title-list_books-span">
                                                    {Books}
                                                </span>
                                                <span>{Price}</span>
                                            </li>
                                            {/* {books?.map(
                                                ({ quantity, book }, id) => {
                                                    return (
                                                        <li key={id}>
                                                            <span>
                                                                {book?.title}
                                                            </span>
                                                            <span>
                                                                {(book?.campaign
                                                                    ? this.newPrice(
                                                                          book?.campaign,
                                                                          book?.price
                                                                      )
                                                                    : book?.price) *
                                                                    quantity}
                                                                $
                                                            </span>
                                                        </li>
                                                    );
                                                }
                                            )}
                                             */}
                                            {this.bookItemsPriceInvoice()}
                                            {(step === 2 || step === 3) && (
                                                <li className="tax">
                                                    <span>{DELEVERYFEE}</span>
                                                    <span>
                                                        {parseInt(
                                                            selectedLocation
                                                                ?.country_info
                                                                ?.delivery_cost ??
                                                                0,
                                                            10
                                                        ) !== 0 &&
                                                            parseInt(
                                                                selectedLocation
                                                                    ?.country_info
                                                                    ?.delivery_cost ??
                                                                    0,
                                                                10
                                                            ) + "$"}
                                                    </span>
                                                </li>
                                            )}

                                            <li className="tax">
                                                <span>{TAX1}</span>
                                                <span>
                                                    {parseInt(
                                                        selectedLocation
                                                            ?.country_info
                                                            ?.tax ?? 0,
                                                        10
                                                    ) !== 0 &&
                                                        parseInt(
                                                            selectedLocation
                                                                ?.country_info
                                                                ?.tax ?? 0,
                                                            10
                                                        ) + "$"}
                                                </span>
                                            </li>

                                            {/* {this.state.deliveryCost && (
                                                <li>
                                                    <span>{DELEVERYFEE}</span>
                                                    <span>0</span>
                                                </li>
                                            )} */}
                                            <li className="total">
                                                <span>{Total}</span>
                                                <span>
                                                    {this.totalAmount(books)}$
                                                </span>
                                            </li>
                                        </ul>
                                        {/* invoice list end  */}

                                        {/* invoice location start  */}
                                        {step === 3 && selectedLocation && (
                                            <div className="invoice-location">
                                                <div className="location__header flex">
                                                    <h6>
                                                        {selectedLocation?.name}
                                                    </h6>
                                                </div>
                                                <p>
                                                    {selectedLocation?.address}
                                                </p>
                                                <div className="location-footer flex">
                                                    <span>
                                                        {
                                                            selectedLocation?.phone
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {/* invoice location end  */}

                                        <button
                                            className="submit-button"
                                            onClick={() => {
                                                if (step === 3) {
                                                    if (
                                                        selectedPayment !== -1
                                                    ) {
                                                        this.checkout();
                                                    } else {
                                                        displayAlert(
                                                            Warning,
                                                            PleaseSelectPaymentMethod,
                                                            "warning"
                                                        );
                                                    }
                                                } else if (step === 2) {
                                                    if (selectedLocation.id) {
                                                        this.setState({
                                                            step: step + 1,
                                                        });
                                                    } else {
                                                        displayAlert(
                                                            Warning,
                                                            PleaseSelectLocation,
                                                            "warning"
                                                        );
                                                    }
                                                } else {
                                                    if (books?.length > 0) {
                                                        this.setState({
                                                            step: step + 1,
                                                        });
                                                    }
                                                }
                                            }}
                                        >
                                            {step === 3 ? CheckOut : Continue}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <div className="empty-img">
                                <img src="/images/empty.png" alt="l" />
                                <h6>{EmptyCart}</h6>
                            </div>
                        )}
                    </div>
                </Container>
                <NewTitles title={BooksYouMayLike} />

                <AgentOf />

                {/* add/edit address modal */}
                <Modal
                    show={AddAddress}
                    width="600"
                    effect="fadeInUp"
                    onHide={this.closeModal}
                    className="location-modal"
                >
                    <div className="modal-title flex">
                        <h5>{AddYourLocation}</h5>
                        <i
                            onClick={this.closeModal}
                            className="fa fa-times"
                        ></i>
                    </div>
                    <div className="address-inputs">
                        <Row>
                            <Col
                                lg={6}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <TextInput
                                    name="name"
                                    label={Name}
                                    placeholder={Name}
                                    value={fields.name}
                                    onFieldChange={onFieldChange}
                                />
                            </Col>
                            <Col
                                lg={6}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <TextInput
                                    name="phone"
                                    label={Phone}
                                    placeholder={Phone}
                                    value={fields.phone}
                                    onFieldChange={onFieldChange}
                                />
                            </Col>

                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <Form.Select
                                    defaultValue="-1"
                                    aria-label="Default select example"
                                    onChange={({ target }) => {
                                        this.setState({
                                            fields: {
                                                ...fields,
                                                country_id: JSON.parse(
                                                    target.value
                                                ).id,
                                                country: JSON.parse(
                                                    target.value
                                                ).name,
                                            },
                                        });
                                    }}
                                >
                                    <option value="-1" disabled>
                                        {selectedCountry}
                                    </option>
                                    {countries.map(({ name, id }) => {
                                        return (
                                            <option
                                                key={id}
                                                value={JSON.stringify({
                                                    name,
                                                    id,
                                                })}
                                            >
                                                {name}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Col>

                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <TextInput
                                    name="address"
                                    label={Address}
                                    placeholder={Address}
                                    value={fields.address}
                                    onFieldChange={onFieldChange}
                                    isTextArea
                                />
                            </Col>

                            <Col lg={12} md={12} sm={12}>
                                <LocationPicker
                                    className="Maps"
                                    containerElement={
                                        <div
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                marginTop: "15px",
                                            }}
                                        />
                                    }
                                    mapElement={
                                        <div
                                            Marker="/img/heart.png"
                                            style={{
                                                height: "400px",
                                            }}
                                        />
                                    }
                                    defaultPosition={defaultPosition}
                                    onChange={this.handleLocationChange}
                                    circleOptions={{
                                        visible: false,
                                    }}
                                />
                            </Col>

                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="locationButton"
                            >
                                <button
                                    className="submit-button"
                                    onClick={() => {
                                        this.addLocation();
                                    }}
                                >
                                    {AddLocation}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                {/* end add/edit address modal */}
            </div>
        );
    }
    selectedLocation = (locationData) => {
        this.setState({
            selectedLocation: locationData,
        });
    };
    payment = (type, text, id) => {
        const { selectedPayment } = this.state;
        const { Sorry, ThisMethodNotInService } = this.props.i18n;

        return (
            <div
                className="payment"
                onClick={() => {
                    if (id === 2) {
                        displayAlert(Sorry, ThisMethodNotInService, "warning");
                    } else {
                        this.setState({
                            selectedPayment: id,
                            CashOnDeliveryCost: 3,
                        });
                    }
                }}
            >
                <div className="payment__Contents flex">
                    <div>
                        <h6>{type}</h6>
                        <span>{text}</span>
                    </div>
                    <div className="flex">
                        <span
                            style={
                                selectedPayment === id
                                    ? { backgroundColor: "#50a433 " }
                                    : null
                            }
                        >
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    /// calculate new price

    newPrice = (campaign, price) => {
        let discountAmount = (price * campaign.percentage) / 100;

        let priceAfterDiscount = 0;
        if (discountAmount > campaign.fixed_amount) {
            priceAfterDiscount = price - campaign.fixed_amount;

            return priceAfterDiscount;
        } else {
            priceAfterDiscount = price - discountAmount;
            return priceAfterDiscount;
        }
    };
    /// calculate total amount

    bookItemsPriceInvoice = () => {
        return this.state.books?.map(({ quantity, book }, id) => {
            return (
                <li key={id}>
                    <span>{book?.title}</span>
                    <span>
                        {(
                            (book?.campaign
                                ? this.newPrice(book?.campaign, book?.price)
                                : book?.price) * quantity
                        ).toFixed(2)}
                        $
                    </span>
                </li>
            );
        });
    };

    bookItemsPrice = () => {
        let total = 0;
        this.state.books?.forEach(({ quantity, book }, id) => {
            if (book?.campaign) {
                total += this.newPrice(book?.campaign, book?.price) * quantity;
            } else {
                total += book?.price * quantity;
            }
        });
        return total;
    };

    totalAmount = (books) => {
        const { selectedLocation, step, CashOnDeliveryCost, selectedPayment } =
            this.state;

        const totalAmount = books?.reduce((acc, currentValue) => {
            let price;

            if (currentValue?.book?.campaign) {
                price = this.newPrice(
                    currentValue?.book?.campaign,
                    currentValue?.book?.price
                );
            } else {
                price = currentValue?.book?.price;
            }

            let TotalPrice = price * currentValue?.quantity;

            return TotalPrice + acc;
        }, 0);

        let finalTotalAmount =
            step < 2
                ? totalAmount
                : totalAmount +
                  parseInt(
                      selectedLocation?.country_info?.delivery_cost ?? 0,
                      10
                  );

        return selectedPayment === 1
            ? (
                  finalTotalAmount +
                  3 +
                  parseInt(selectedLocation?.country_info?.tax ?? 0, 10)
              ).toFixed(2)
            : (
                  finalTotalAmount +
                  parseInt(selectedLocation?.country_info?.tax ?? 0, 10)
              ).toFixed(2);
    };

    /// add location
    addLocation = async () => {
        const { fields } = this.state;
        const { success } = await locationService.addLocation(fields);
        const { Done, LocationAdded } = this.props.i18n;
        if (!success) return;
        displayAlert(Done, LocationAdded, "success").then(() => {
            this.getUserAddresses();
            this.setState({
                AddAddress: false,
                fields: {
                    address: "",
                    name: "",
                    phone: "",
                    country: "",
                    longitude: "",
                    latitude: "",
                },
            });
        });
    };

    // check out function

    checkout = async () => {
        const { selectedPayment, selectedLocation, books } = this.state;
        const { Done, OrderCheckOutDoneSuccessfully } = this.props.i18n;
        const { success } = await generalServices.checkout({
            payment_method: selectedPayment === 1 ? "cash" : "visa",
            // books_amount: this.totalAmount(books),
            books_amount: this.bookItemsPrice(),
            tax_amount: parseInt(selectedLocation?.country_info?.tax ?? 0, 10),
            discount_amount: 0,
            total_amount: this.totalAmount(books),
            delivery_amount: parseInt(
                selectedLocation?.country_info?.delivery_cost ?? 0,
                10
            ),
            user_location_id: selectedLocation.id,
        });

        if (!success) return;
        displayAlert(Done, OrderCheckOutDoneSuccessfully, "success").then(
            () => {
                // this.getCartData();
                this.props.setUser({ cart_count: 0 });

                window.location.href = "/MyOrders";

                // this.setState({
                //     AddAddress: false,
                //     step: 1,
                //     fields: {
                //         address: "",
                //         name: "",
                //         phone: "",
                //         country: "",
                //     },
                // });
            }
        );
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    currentUser,
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
});

export default connect(mapStateToProps, { setUser })(Cart);
// export default connect(mapStateToProps, { setUser })(
//     GoogleApiWrapper({
//         apiKey: "AIzaSyCKmi_6e15eYNM6sP-izvaILQad6TkKS94",
//     })(Cart)
// );
