import React, { Component } from "react";
import { Container, DropdownButton, Dropdown, Modal, Accordion } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import { authService } from "../../services/AuthService";
import { setUser } from "../../redux/actions-reducers/user";
import { setLanguage } from "../../redux/actions-reducers/language";
import { connect } from "react-redux";
import { apiService } from "../../services/ApiService";
import Global from "../../language/Global.json";
import MainLoader from "./MainLoader";
import MultiSideMenu from "./MultiLevelSlider";
import { generalServices } from "../../services/GeneralServices";
class Header extends Component {
    state = {
        menuOpen: false,
        otherHeader: false,
        activeHeader: 0,
        IsArabic: false,
        languageLoader: false,
        fullscreen: true,
        show: false,
        classes: [],
        menuOpenClasses: false,
        settingData: {},
    };
    componentDidMount() {
        this.getClassesNew();
        this.getSettings();

        if (window.location.pathname === "/") {
            this.setState({
                otherHeader: true,
            });
            this.props.language === "ar" ? this.setState({ IsArabic: true }) : this.setState({ IsArabic: false });
        }
        // active header
        if (window.location.pathname.toLowerCase() === "/") {
            this.setState({
                activeHeader: 1,
            });
        }
        if (window.location.pathname.toLowerCase() === "/publicpage/about") {
            this.setState({
                activeHeader: 2,
            });
        }
        if (window.location.pathname.toLowerCase() === "/teachersarea") {
            this.setState({
                activeHeader: 3,
            });
        }
        if (window.location.pathname.toLowerCase() === "/contactus") {
            this.setState({
                activeHeader: 4,
            });
        }
        if (window.location.pathname.toLowerCase() === "/DigitalPlatforms") {
            this.setState({
                activeHeader: 5,
            });
        }
    }

    async getSettings() {
        const { success, data } = await generalServices.getSettings();
        if (!success) return;
        this.setState({
            settingData: data,
        });
    }

    getClassesNew = async () => {
        const { success, data } = await generalServices.getClassesNew();
        if (!success) return;
        this.setState({ classes: data });
    };
    get accessToken() {
        return window.localStorage.getItem("accessToken");
    }
    onLanguageChange = async () => {
        this.setState({ languageLoader: true }, async () => {
            setTimeout(async () => {
                let currentLang;
                if (this.props.language === "en") {
                    localStorage.setItem("language", "ar");
                    currentLang = "ar";
                } else {
                    localStorage.setItem("language", "en");
                    currentLang = "en";
                }
                await this.props.setLanguage(currentLang);
                window.location.reload();
            }, 500);
        });
    };

    handleShow = (breakpoint) => {
        this.setState({ fullscreen: breakpoint, show: true });
    };
    Example = () => {
        // const values = [true];
        // const { fullscreen } = this.state;
        // const { show } = this.state;

        return (
            <>
                {/* <button className="me-2" onClick={() => handleShow(true)}>
                    Full screen
                </button> */}
                {/* ))} */}
                {/* <Modal
                    show={show}
                    fullscreen={fullscreen}
                    onHide={() => this.setState({ show: false })}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>Modal body content</Modal.Body>
                </Modal> */}
            </>
        );
    };

    render() {
        const { menuOpen, activeHeader, languageLoader, classes, menuOpenClasses, settingData } = this.state;
        const {
            i18n: { Home, About, ContactUs, TeachersArea, Welcome, Profile, MyOrders, MyLocations, LogOut, logIn, SignUp, lang, LogIn, EN, OurTeam, Cart, favourite, ClassesTitle, OurCatalogue, digitalPlatformsName },
            language,
        } = this.props;
        console.log(classes);

        return (
            <div
                className={`header ${language === "ar" && "header-arabic"}`}
                // style={otherHeader ? { position: "absolute" } : null}
            >
                <Container>
                    <div className="header-contents flex">
                        <a href="/">
                            <img className="logo-image" src={language === "ar" ? "/images/Artboard 1 copy 3@2x.png" : "/images/Artboard 1@2x.png"} alt="k" />
                        </a>
                        <ul className="flex header-list">
                            <li className={activeHeader === 1 ? "activeHeader" : ""}>
                                <a href="/">{Home}</a>
                            </li>
                            <li className={activeHeader === 2 ? "activeHeader" : ""}>
                                <a href="/PublicPage/about">{About}</a>
                            </li>
                            {/* <li
                                className={
                                    activeHeader === 3 ? "activeHeader" : ""
                                }
                            >
                                <a href="/">{TeachersArea}</a>
                            </li> */}
                            <li className={activeHeader === 3 ? "activeHeader" : ""}>
                                <button
                                    className="classesButton"
                                    // onClick={() => this.handleSidebarToggle()}
                                >
                                    <a href="/CategoryPage">{ClassesTitle}</a>
                                </button>
                                <div className="category-push-menu">
                                    {/* <MultiSideMenu
                                        menuOpen={menuOpenClasses}
                                        options={classes.map((_class) => {
                                            return {
                                                content: [
                                                    {
                                                        id: _class.id,
                                                        name: `${_class.name}`,
                                                        // subjects start
                                                        children: _class.subjects?.map((sub) => {
                                                            return {
                                                                content: [
                                                                    {
                                                                        name: sub.name,
                                                                        // children: sub.series.map((se) => {
                                                                        //     return {
                                                                        //         content: [
                                                                        //             {
                                                                        //                 name: se.name,
                                                                        //                 to: `/CategoryPage/${_class.id}/${sub.id}/${se.id}`,
                                                                        //             },
                                                                        //         ],
                                                                        //     };
                                                                        // }),
                                                                    },
                                                                ],
                                                            };
                                                        }),
                                                    },
                                                ],
                                            };
                                        })}
                                        handleSidebarToggle={this.handleSidebarToggle}
                                    /> */}
                                    {/* using in our button to open the sidebar */}
                                </div>
                            </li>
                            <li className={activeHeader === 4 ? "activeHeader" : ""}>
                                <a href="/ContactUs">{ContactUs}</a>
                            </li>
                            <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                <a href="/DigitalPlatforms">{digitalPlatformsName}</a>
                            </li>

                            <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                <a href={settingData?.flip_book} target="_blank">
                                    {OurCatalogue}
                                </a>
                            </li>
                        </ul>
                        {/* aaaa */}
                        <div className="cart-fav flex">
                            <a href={this.accessToken ? "/Cart" : "/login"} className="single-cart">
                                <img src="/images/fi-rr-shopping-cart.svg" alt="k" />
                                {this.props.currentUser.cart_count > 0 && <span>{this.props.currentUser.cart_count}</span>}
                            </a>
                            <a href={this.accessToken ? "/Favorites" : "/login"} className="single-cart">
                                <img src="/images/fi-rr-heart.svg" alt="k" />
                                {this.props.currentUser.fav_count > 0 && <span>{this.props.currentUser.fav_count}</span>}
                            </a>
                            <span
                                className="single-cart language-switch"
                                onClick={() => {
                                    this.onLanguageChange();
                                }}
                            >
                                {EN}
                            </span>
                        </div>
                        {languageLoader && (
                            <Modal show={true} fullscreen={true} onHide={() => this.setState({ show: false })} className="languageLoaderModal">
                                <Modal.Body>
                                    <MainLoader />{" "}
                                </Modal.Body>
                            </Modal>
                        )}
                        <div className="user-auth-Buttons">
                            {this.accessToken ? (
                                <div className="user-dropdown flex">
                                    <img src={this.props.currentUser.image ? apiService.imageLink + this.props.currentUser.image : "/images/Group 510@3x.png"} alt="l" />

                                    <DropdownButton
                                        id="dropdown-basic-button"
                                        title={
                                            <div>
                                                <span>{Welcome}</span>
                                                <p>{this.props.currentUser.name}</p>
                                            </div>
                                        }
                                    >
                                        <Dropdown.Item href="/profile">{Profile}</Dropdown.Item>
                                        <Dropdown.Item href="/MyOrders">{MyOrders}</Dropdown.Item>

                                        <Dropdown.Item href="/MyLocations">{MyLocations}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => authService.logout()}>{LogOut}</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            ) : (
                                <div className="auth-Buttons">
                                    <a href="/login">{logIn}</a> / <a href="/signUp">{SignUp}</a>
                                </div>
                            )}
                        </div>
                        {/* push menu start  */}
                        <div className="mobile-push-menu">
                            <Menu right={language === "ar" ? true : false} isOpen={menuOpen} customBurgerIcon={<i className="fa fa-bars" />} customCrossIcon={<i className="fa fa-times croosIconMenu" />}>
                                <div className="sideMenu_logo">
                                    <a href="/" onClick={() => this.closeMenu()}>
                                        <img className="logo-image" src={language === "ar" ? "/images/Artboard 1 copy 3@2x.png" : "/images/Artboard 1@2x.png"} alt="logo" />
                                    </a>
                                </div>

                                <div className="sideMenu_List">
                                    <ul className="mb-0 mt-3">
                                        {this.accessToken ? (
                                            <Accordion flush defaultActiveKey="1">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <div className="sideMenu_List__img flex">
                                                            <img src={this.props.currentUser.image ? apiService.imageLink + this.props.currentUser.image : "/images/Group 510@3x.png"} alt="l" />
                                                            <span>{this.props.currentUser.name}</span>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <a href="/profile">{Profile}</a>
                                                        <a href="/MyOrders">{MyOrders}</a>

                                                        <a href="/MyLocations">{MyLocations}</a>

                                                        <a href={"/Cart"} className="cart-and-favourite-mobile">
                                                            <p>{Cart}</p>
                                                            {this.props.currentUser.cart_count > 0 && <p className="cart-and-favourite-mobile__qty">{this.props.currentUser.cart_count}</p>}
                                                        </a>
                                                        <a href={"/Favorites"} className="cart-and-favourite-mobile">
                                                            <p>{favourite}</p>
                                                            {this.props.currentUser.fav_count > 0 && <p className="cart-and-favourite-mobile__qty">{this.props.currentUser.fav_count}</p>}
                                                        </a>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        ) : null}

                                        <li>
                                            <a href="/" onClick={() => this.closeMenu()}>
                                                {Home}
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/PublicPage/about" onClick={() => this.closeMenu()}>
                                                {About}
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a
                                                href="/"
                                                onClick={() => this.closeMenu()}
                                            >
                                                {TeachersArea}
                                            </a>
                                        </li> */}
                                        <li className={activeHeader === 3 ? "activeHeader" : ""}>
                                            <a href="/CategoryPage/1/1">{ClassesTitle}</a>
                                        </li>
                                        <li className={activeHeader === 4 ? "activeHeader" : ""}>
                                            <a href="/ContactUs">{ContactUs}</a>
                                        </li>
                                        <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                            <a href="/DigitalPlatforms">{digitalPlatformsName}</a>
                                        </li>

                                        <li className={activeHeader === 5 ? "activeHeader" : ""}>
                                            <a href={"/" + settingData?.flip_book} target="_blank">
                                                {OurCatalogue}
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#" onClick={() => this.onLanguageChange()}>
                                                {lang}
                                            </a>
                                        </li>
                                        {this.accessToken ? (
                                            <li>
                                                <a href="/" onClick={() => authService.logout()}>
                                                    {LogOut}
                                                </a>
                                            </li>
                                        ) : (
                                            <li>
                                                <a href="/Login" onClick={() => this.closeMenu()}>
                                                    {LogIn}
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </Menu>
                        </div>
                        {/* push menu start  */}
                    </div>
                </Container>
            </div>
        );
    }

    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
    handleSidebarToggle = () => {
        this.setState((state) => ({ menuOpenClasses: !state.menuOpenClasses }));
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(Header);
