import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/style/components/pages/home/_theBlog.scss";
import { apiService } from "../../../services/ApiService";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json";
import React, { useEffect, useState } from "react";
import { generalServices } from "../../../services/GeneralServices";
function TheBlog({ data }) {
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ar" ? Global.ar : Global.en;
    const { DigitalSolutions } = i18n;
    const [digitalSolutions, setdigitalSolutions] = useState([]);
    const getDigitalSolutions = async () => {
        const { data } = await generalServices.getDigitalSolutions();
        setdigitalSolutions(data);
    };
    useEffect(() => {
        getDigitalSolutions();
    }, []);
    return (
        <div className="The-blog">
            {/* <img
                className="the-blog__background-image"
                src="images/box.png"
                alt="1"
            /> */}
            <Container>
                <div className="section-title">
                    <h3>{DigitalSolutions}</h3>
                </div>
                <Row>
                    {/* {data.map(({ title, text, image_full, id, key }) => {
                        return ( */}
                    {digitalSolutions.map(({ name, image, url }) => {
                        return (
                            <Col key={name} className="" lg={4} md={4} sm={6} className="mb-4">
                                <div className="the-blog__section" data-aos="fade-up" data-aos-offset="200" data-aos-delay="3" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-bottom">
                                    <a href={url} target="_blank" className="the-blog__image-container" rel="noreferrer">
                                        <img className="the-blog__left-section__image" alt="1" src={apiService.imageLink + image} />
                                        <p className="the-blog__section__date"></p>

                                        <h4 className="The-blog__section__title The-blog__section__title-big-one">{name}</h4>
                                    </a>
                                </div>
                            </Col>
                        );
                    })}
                    {/* })} */}
                    {/* <Col className="" lg={3} md={4} sm={6}>
                        <div className="the-blog__section">
                            <div className="the-blog__image-container">
                                <img className="the-blog__left-section__image" src="images/books2.png" />
                                <p className="the-blog__section__date">28.07.2021</p>

                                <h4 className="The-blog__section__title">Blog Heading Line</h4>
                                <p className="The-blog__section__description">In 2011, AERO (American Education Reaches Out) developed the Standardds & Benchmarks of the Social Studies.</p>
                            </div>
                        </div>
                    </Col>

                    <Col className="" lg={3} md={4} sm={6}>
                        <div className="the-blog__section">
                            <div className="the-blog__image-container">
                                <img className="the-blog__left-section__image" src="images/books2.png" />
                                <p className="the-blog__section__date">28.07.2021</p>

                                <h4 className="The-blog__section__title">Blog Heading Line</h4>
                                <p className="The-blog__section__description">In 2011, AERO (American Education Reaches Out) developed the Standardds & Benchmarks of the Social Studies.</p>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
}

export default TheBlog;
